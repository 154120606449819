<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="px-2"
        color="#999999"
        small
        v-bind="attrs"
        depressed
        v-on="on">
        <v-icon
          size="16"
          color="#ffffff">
          $paper_trash_outline
        </v-icon>
      </v-btn>
    </template>
    <confirm-dialog-card
      :icon-size="58"
      :loading="submitting"
      type="error"
      icon="$paper_trash_outline"
      :title="$t('papers.delete.title')"
      :description="$t('papers.delete.description')"
      :text-btn-confirm="$t('papers.delete.submit')"
      :text-btn-close="$t('app.btn.cancel')"
      @close="setDialog(false)"
      @confirm="submit()"
    />
  </v-dialog>
</template>

<script>
import { DELETE_PAPERGROUP } from '@/resources/graphql'
import ConfirmDialogCard from '@/components/Card/ConfirmDialogCard.vue'

export default {
  components: {
    ConfirmDialogCard
  },
  props: {
    itemId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    async submit () {
      this.submitting = true
      await this.$apollo.mutate({
        mutation: DELETE_PAPERGROUP,
        variables: {
          input: {
            id: Number(this.itemId)
          }
        }
      })
      this.submitting = false
      this.setDialog(false)
      this.$emit('submitted')
    }
  }
}
</script>

<style scoped>

</style>
