<template>
  <v-card>
    <div class="d-flex pa-2">
      <v-spacer />
      <v-btn
        icon
        small
        @click="$emit('close')">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <div class="text-center mb-1">
      <v-icon
        v-if="icon"
        :size="iconSize"
        :color="color">
        {{ icon }}
      </v-icon>
    </div>
    <v-card-title class="subtitle-1 justify-center">
      {{ title }}
    </v-card-title>
    <v-card-text class="text-center text--disabled">
      <div class="mx-10">
        {{ description }}
      </div>
    </v-card-text>
    <v-card-actions class="pt-4 bg--disabled border-top--color">
      <v-spacer />
      <v-btn
        color="blue darken-1"
        rounded
        text
        @click="$emit('close')">
        {{ textBtnClose }}
      </v-btn>
      <v-btn
        :color="color"
        class="px-3"
        depressed
        rounded
        :dark="!loading"
        :disabled="loading"
        @click="$emit('confirm')">
        <v-progress-circular
          v-if="loading"
          class="mr-2"
          size="16"
          width="2"
          indeterminate />
        {{ textBtnConfirm }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: () => ''
    },
    iconSize: {
      type: Number,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    description: {
      type: String,
      default: () => ''
    },
    textBtnClose: {
      type: String,
      default: () => 'Close'
    },
    textBtnConfirm: {
      type: String,
      default: () => 'Confirm'
    },
    type: {
      type: String,
      default: () => 'error'
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    color () {
      switch (this.type) {
        case 'error':
          return '#F35D5D'
        default:
          return 'primary'
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>
  .bg--disabled {
    background: #FBFBFB;
  }
  .border-top--color {
    border-top: 1px solid #EEEEEE;
  }
</style>
