var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "px-2",
                      attrs: { color: "#999999", small: "", depressed: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { size: "16", color: "#ffffff" } }, [
                    _vm._v(" $paper_trash_outline ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("confirm-dialog-card", {
        attrs: {
          "icon-size": 58,
          loading: _vm.submitting,
          type: "error",
          icon: "$paper_trash_outline",
          title: _vm.$t("papers.delete.title"),
          description: _vm.$t("papers.delete.description"),
          "text-btn-confirm": _vm.$t("papers.delete.submit"),
          "text-btn-close": _vm.$t("app.btn.cancel")
        },
        on: {
          close: function($event) {
            return _vm.setDialog(false)
          },
          confirm: function($event) {
            return _vm.submit()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }