<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="px-2"
        color="#999999"
        small
        v-bind="attrs"
        depressed
        v-on="on">
        <v-icon
          size="16"
          color="#ffffff">
          mdi-content-copy
        </v-icon>
      </v-btn>
    </template>
    <confirm-dialog-card
      :icon-size="58"
      :loading="submitting"
      type="primary"
      icon="mdi-content-copy"
      :title="$t('papers.copy.title')"
      :description="$t('papers.copy.description')"
      :text-btn-confirm="$t('papers.copy.submit')"
      :text-btn-close="$t('app.btn.cancel')"
      @close="setDialog(false)"
      @confirm="submit()"
    />
  </v-dialog>
</template>

<script>
import { GET_PAPERGROUP, CREATE_PAPERGROUP, CREATE_PAPER } from '@/resources/graphql'
import ConfirmDialogCard from '@/components/Card/ConfirmDialogCard.vue'

export default {
  components: {
    ConfirmDialogCard
  },
  props: {
    itemId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    async submit () {
      this.submitting = true
      const { data: { paperGroup } } = await this.$apollo.query({
        query: GET_PAPERGROUP,
        variables: {
          id: Number(this.itemId)
        }
      })
      const { data: { createPaperGroup } } = await this.$apollo.mutate({
        mutation: CREATE_PAPERGROUP,
        variables: {
          input: {
            name: `${paperGroup.name } - copy`,
            savedCalculations: paperGroup.savedCalculations
          }
        }
      })
      const paperGroupId = createPaperGroup.paperGroup.id
      const name = createPaperGroup.paperGroup.name
      const paper = paperGroup.papers.nodes.find((item) => item.active)
      if (paper) {
        await this.$apollo.mutate({
          mutation: CREATE_PAPER,
          variables: {
            input: {
              paperGroupId,
              name,
              width: paper.width,
              height: paper.height,
              paperSize: paper.paperSize,
              condition: paper.condition,
              canvas: paper.canvas,
              active: true
            }
          }
        })
      }
      this.$router.push({ name: 'Paper', params: { id: paperGroupId } })
      this.submitting = false
      this.setDialog(false)
    }
  }
}
</script>

<style scoped>

</style>
