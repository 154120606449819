<template>
  <div id="app-receipt">
    <div class="d-flex">
      <header-label
        :label="$t('papers.header')"
        prepend-icon="$g_receipt" />
      <v-spacer />
      <new-paper type="button" />
    </div>
    <div class="d-flex flex-wrap">
      <div
        v-for="item in paperGroups"
        :key="`card-receipt-${item.id}`"
        class="mr-6 mb-6">
        <paper-card
          :item-id="item.id"
          :height="item.height"
          :width="item.width"
          :paper-size="item.paperSize"
          :canvas="item.canvas || []"
          @removed="refetch()" />
        <v-tooltip
          :disabled="item.name.length < 16"
          bottom>
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="mt-2 paper-name font-weight-medium secondary--text"
              :class="{
                'paper-name-caption text-overflow': item.name.length >= 16,
              }"
              v-on="on">
              {{ item.name }}
            </div>
          </template>
          <v-card
            width="320"
            color="transparent"
            flat
            dark>
            {{ item.name }}
          </v-card>
        </v-tooltip>
      </div>
      <div
        class="mr-6 mb-6">
        <new-paper type="card" />
      </div>
    </div>
    <infinite-loading
      :identifier="infiniteId"
      @infinite="infiniteHandler">
      <div
        slot="no-more"
        class="caption secondary--text">
        {{ $t('app.nomoreitem') }} :)
      </div>
      <div
        slot="no-results"
        class="caption secondary--text">
        {{ $t('app.noitem') }} :)
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import _ from 'lodash'
import { GET_PAPERGROUPS } from '@/resources/graphql'
import NewPaper from './components/NewPaper.vue'
import PaperCard from './components/PaperCard.vue'

export default {
  name: 'Papers',
  components: {
    NewPaper,
    PaperCard
  },
  apollo: {
    paperGroups: () => ({
      query: GET_PAPERGROUPS,
      fetchPolicy: 'no-cache',
      debounce: 300,
      variables () {
        return {
          first: 24,
          after: null
        }
      },
      update: ({ paperGroups }) => paperGroups.nodes,
      result ({ data: { paperGroups } }) {
        this.nextCursor = paperGroups.pageInfo.endCursor
        this.infiniteId += 1
      }
    })
  },
  data () {
    return {
      infiniteId: +new Date(),
      paperGroups: [],
      nextCursor: null
    }
  },
  methods: {
    getCurrentDraftId (group) {
      const paper = group.papers.nodes.find((item) => item.active)
      return paper?.canvas || []
    },
    refetch () {
      this.$apollo.queries.paperGroups.refetch()
    },
    async infiniteHandler ($state) {
      await this.$apollo.queries.paperGroups.fetchMore({
        variables: {
          first: 12,
          after: this.nextCursor
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult.paperGroups.nodes.length) {
            this.nextCursor = fetchMoreResult.paperGroups.pageInfo.endCursor
            $state.loaded()
          } else {
            $state.complete()
          }
          return ({
            paperGroups: {
              __typename: previousResult.paperGroups.__typename,
              pageInfo: fetchMoreResult.paperGroups.pageInfo,
              nodes: _.uniqBy([
                ...previousResult.paperGroups.nodes,
                ...fetchMoreResult.paperGroups.nodes
              ], 'id')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .paper-name {
    width: 180px;
  }
  .paper-name-caption {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
  }
</style>
