var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "d-flex justify-end",
      style: {
        backgroundImage: _vm.base64 ? "url(" + _vm.base64 + ")" : "#ffffff",
        backgroundSize: "contain"
      },
      attrs: { color: "#ffffff", height: "260", width: "185", flat: "" }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex ma-1" },
        [
          _c(
            "div",
            { staticClass: "mr-1" },
            [
              _c("remove-paper", {
                attrs: { "item-id": _vm.itemId },
                on: {
                  submitted: function($event) {
                    return _vm.$emit("removed")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mr-1" },
            [
              _c("copy-paper", {
                staticClass: "ml-1",
                attrs: { "item-id": _vm.itemId }
              })
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                to: "/papers/" + _vm.itemId,
                color: "primary",
                small: "",
                depressed: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("app.btn.edit")) + " ")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "overflow-pixel" }, [
        _c("div", {
          staticClass: "relative",
          attrs: { id: "group-canvas-" + _vm.itemId }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }