var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app-receipt" } },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("header-label", {
            attrs: {
              label: _vm.$t("papers.header"),
              "prepend-icon": "$g_receipt"
            }
          }),
          _c("v-spacer"),
          _c("new-paper", { attrs: { type: "button" } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap" },
        [
          _vm._l(_vm.paperGroups, function(item) {
            return _c(
              "div",
              { key: "card-receipt-" + item.id, staticClass: "mr-6 mb-6" },
              [
                _c("paper-card", {
                  attrs: {
                    "item-id": item.id,
                    height: item.height,
                    width: item.width,
                    "paper-size": item.paperSize,
                    canvas: item.canvas || []
                  },
                  on: {
                    removed: function($event) {
                      return _vm.refetch()
                    }
                  }
                }),
                _c(
                  "v-tooltip",
                  {
                    attrs: { disabled: item.name.length < 16, bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "mt-2 paper-name font-weight-medium secondary--text",
                                      class: {
                                        "paper-name-caption text-overflow":
                                          item.name.length >= 16
                                      }
                                    },
                                    "div",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c(
                      "v-card",
                      {
                        attrs: {
                          width: "320",
                          color: "transparent",
                          flat: "",
                          dark: ""
                        }
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "mr-6 mb-6" },
            [_c("new-paper", { attrs: { type: "card" } })],
            1
          )
        ],
        2
      ),
      _c(
        "infinite-loading",
        {
          attrs: { identifier: _vm.infiniteId },
          on: { infinite: _vm.infiniteHandler }
        },
        [
          _c(
            "div",
            {
              staticClass: "caption secondary--text",
              attrs: { slot: "no-more" },
              slot: "no-more"
            },
            [_vm._v(" " + _vm._s(_vm.$t("app.nomoreitem")) + " :) ")]
          ),
          _c(
            "div",
            {
              staticClass: "caption secondary--text",
              attrs: { slot: "no-results" },
              slot: "no-results"
            },
            [_vm._v(" " + _vm._s(_vm.$t("app.noitem")) + " :) ")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }