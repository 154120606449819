var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "div",
        { staticClass: "d-flex pa-2" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", small: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v(" mdi-close ")])],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center mb-1" },
        [
          _vm.icon
            ? _c(
                "v-icon",
                { attrs: { size: _vm.iconSize, color: _vm.color } },
                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("v-card-title", { staticClass: "subtitle-1 justify-center" }, [
        _vm._v(" " + _vm._s(_vm.title) + " ")
      ]),
      _c("v-card-text", { staticClass: "text-center text--disabled" }, [
        _c("div", { staticClass: "mx-10" }, [
          _vm._v(" " + _vm._s(_vm.description) + " ")
        ])
      ]),
      _c(
        "v-card-actions",
        { staticClass: "pt-4 bg--disabled border-top--color" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", rounded: "", text: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.textBtnClose) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "px-3",
              attrs: {
                color: _vm.color,
                depressed: "",
                rounded: "",
                dark: !_vm.loading,
                disabled: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.$emit("confirm")
                }
              }
            },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    staticClass: "mr-2",
                    attrs: { size: "16", width: "2", indeterminate: "" }
                  })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.textBtnConfirm) + " ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }